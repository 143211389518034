/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        enableLazyLoad();
        menuTogglers();
        historyManager();
        setTimeout ( formatForms, 500 );
        widgetMenuToDropdown();
        setTimeout ( widgetMenuCloneForMobile, 1000 );
        ajutsChangeLink();
        mapaWebAccordion();
        addDotsToWidgetLists();
        lightBoxGalleries();

        
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        linkMobileTemesAmbits();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Blog / Noticies
    'blog': {
      init: function() {
        // JavaScript to be fired on the home page
        ajaxLoadNoticias();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Blog / Noticies
    'page_template_template_equipaments': {
      init: function() {
        // JavaScript to be fired on the home page
        ajaxLoadEquipaments();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Adreces
    
    'post_type_archive_adreces': {
      init: function() {
        // JavaScript to be fired on the about us page
        filtrar_adreces();
        modal_adreces();
      }
    },
    'single_adreces': { // equipament
      init: function() {
        // JavaScript to be fired on the about us page
        mapaFitxaEquipament();
      }
    },
    'single_ambits': { // equipament
      init: function() {
        // JavaScript to be fired on the about us page

        var mapes = $('.widget-mapa').length;

        //console.log('Mapes: '+mapes);

        if ( mapes ) {
          for ( n=1; n<mapes+1; n++ ) {
            //.log('.widget-mapa-'+n+' mapa-'+n);
              mapaFitxaEquipament('.widget-mapa-'+n , 'mapa-'+n);
          }
        } 
        
      }
    },
    'post_type_archive_tramits': {
      init: function() {
        // JavaScript to be fired on the about us page
        filtrar_tramits_per_ambit();
      }
    },
    'single_tramits': { // tramit
      init: function() {
        // JavaScript to be fired on the about us page
        mapaFitxaEquipament();
      }
    },
    'post_type_archive_agenda': {
      init: function() {
        // JavaScript to be fired on the about us page
        //ajaxLoadAgenda();
        calendari();
        filtresAgenda();
        uiCalendariMobile();
        agendaSharingTools();
        //setInterval(canviNomColumnaDies, 1000);
        canviNomColumnaDies();
        setInterval(canviNomColumnaDies,100);
      }
    },
    'single_agenda': { // equipament
      init: function() {
        // JavaScript to be fired on single agenda item
        agendaSharingTools();
        if ( $('.widget-mapa').length ) {
          mapaFitxaEquipament();
        }
      }
    },
    'post_type_archive_treball': {
      init: function() {
        // JavaScript to be fired on archive ofertes feina publica
        filtrar_feina();
      }
    },
    'page_template_page_citaprevia': {
      init: function() {
        // JavaScript to be fired on archive ofertes feina publica
        canviNomColumnaDies();
        setInterval(canviNomColumnaDies,100);
      }
    },

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);





  // BEGIN CUSTOM FUNCTIONS AJUNTAMENT

    //activate lazyload for images above the fold
    var enableLazyLoad = function () {

      $('.lazy').Lazy({
        effect: 'fadeIn',
        effectTime: 500
      });
  
      //force lazyload in unvisible elements like carousels, etc
      $('.uk-slidenav').bind('click',function(){
        $('.uk-slider .lazy').Lazy({});
      });
  
    }









    // menu togglers barra superior menu i pagines vistes 
    var menuTogglers = function () {
      // boton menu
      $('.menu-switch-icon, .menu-panel-menu .close-btn').click(function(){
        $('.menu-overlay-wrapper, .menu-panel-menu').toggleClass('uk-hidden');
      });




      // boton paginas visitadas

      $('.history-btn, .menu-panel-history .close-btn').click(function(){
        $('.menu-overlay-wrapper, .menu-panel-history').toggleClass('uk-hidden');
        $(window).resize(); // <- forces dynamic grid isotope style redraw
      });

      $('.menu-overlay-wrapper').click(function(){
        $('.menu-panel-history, .menu-panel-menu, .menu-overlay-wrapper').addClass('uk-hidden');
      });
    }












    // adreces i telefons, filtre cercador intern
    var filtrar_adreces = function () {

        function checkresults() {
          var results = $('.ajm-adreca:visible').length;
          if ( !results || results==0 ) {
            $('.no-results').show();
          } else {
            $('.no-results').hide();
          }
        }

        // cercar per text

        $('.ajm-search-box-adreces').change( function() {
          $('.llistat-adreces-principal .ajm-adreca').hide();
          var searchstring = $(this).val().toLowerCase(); // cercar tot sempre en minuscules
          console.log(searchstring);
          $('.llistat-adreces-principal .ajm-adreca:contains('+searchstring+')').show();
          checkresults();
        });

        // filtrar categories

        $('.widget-categories-adreces li a').click(function(e){
          e.preventDefault();
          console.log('click categoria adreça');
          var catselected = $(this).attr('href').replace('#','');
          console.log (catselected);
          $('.llistat-adreces-principal .ajm-adreca').hide();
          $('.llistat-adreces-principal .ajm-adreca[data-category*="'+catselected+'"]').show();
          checkresults();
        });

        // reset

        $('.widget-categories-adreces-reset').click(function(){
          $('.llistat-adreces-principal .ajm-adreca').show();
          $('.no-results').hide();
        });

    }














    // ambits, filtre cercador intern
    var filtrar_tramits_per_ambit = function () {

      function checkresults() {
        var results = $('.ajm-tramit:visible').length;
        if ( !results || results==0 ) {
          $('.no-results').show();
        } else {
          $('.no-results').hide();
        }
      }

      // cercar per text

      $('.ajm-search-box-adreces').change( function() {
        $('.llistat-tramits-principal .ajm-tramit').hide();
        var searchstring = $(this).val().toLowerCase(); // cercar tot sempre en minuscules
        console.log(searchstring);
        $('.llistat-tramits-principal .ajm-tramit:contains('+searchstring+')').show();
        checkresults();
      });

      // filtrar categories

      $('.widget-categories-tramits li a').click(function(e){
        e.preventDefault();
        console.log('click categoria adreça');
        var catselected = $(this).attr('href').replace('#','');
        console.log (catselected);
        $('.llistat-tramits-principal .ajm-tramit').hide();
        $('.llistat-tramits-principal .ajm-tramit[data-ambits*="'+catselected+'"]').show();
        checkresults();
      });

      // reset

      $('.widget-categories-adreces-reset').click(function(){
        $('.llistat-tramits-principal .ajm-tramit').show();
        $('.no-results').hide();
      });

  }










    // Ofeertes publiques de feina, filtre cercador intern
    var filtrar_feina = function () {

      function checkresults() {
        var results = $('.ajm-feina:visible').length;
        if ( !results || results==0 ) {
          $('.no-results').show();
        } else {
          $('.no-results').hide();
        }
      }

      // cercar per text

      $('.ajm-search-box-feina').change( function() {
        $('.llistat-ofertes-feina .ajm-feina').hide();
        var searchstring = $(this).val().toLowerCase(); // cercar tot sempre en minuscules
        console.log(searchstring);
        $('.llistat-ofertes-feina .ajm-feina:contains('+searchstring+')').show();
        checkresults();
      });

      // filtrar categories

      $('.widget-categories-adreces li a').click(function(e){
        e.preventDefault();
        console.log('click categoria feina');
        var catselected = $(this).attr('href').replace('#','');
        console.log (catselected);
        $('.llistat-ofertes-feina .ajm-feina').hide();
        $('.llistat-ofertes-feina .ajm-feina[data-category*="'+catselected+'"]').show();
        checkresults();
      });

      // reset

      $('.widget-categories-feina-reset').click(function(){
        $('.llistat-ofertes-feina .ajm-feina').show();
        $('.no-results').hide();
      });

  }



















    var historyManager = function () {
      var history;

      function getHistory() { 
          var tmp = $.cookie("ajm_history");
          if (tmp===undefined || tmp===null) tmp = "";
          if ($.trim(tmp)=="") tmp = [];
          else tmp = tmp.split("||");
          history = [];
          $.each(tmp, function(){
              var split = this.split("|");
              history.push({
                  title: split[0],
                  url: split[1]
              });
          });
      }

      function saveHistory() {
          var tmp = [];
          $.each(history, function(){
              tmp.push(this.title+"|"+this.url);
          });
          $.cookie("ajm_history",tmp.join("||"),{ expires: 60, path: "/" });
      }

      function addToHistory(title,url) {
          var newHistory = []
          $.each(history, function(){
              if (this.url!=url) newHistory.push(this);
          });
          history = newHistory;
          if (history.length>=12) { // max num planes guardar
              history.shift();
          }
          history.push({
              title: title,
              url: url
          });
          saveHistory();
          writeHistory();
      }

      function writeHistory() {
          var list = $("<ul class='uk-grid uk-grid-small history-realtime-list uk-margin-top-remove' data-uk-grid='{gutter:10}' />");
          $.each(history, function() {
              var element = $("<li class='uk-width-medium-1-3' />");
              element.append('<button class="reset-btn hist-btn hist-btn-save" title="'+str_history_fixar_enllac+'" />');
              var link = $("<a />");
              link.attr("href",this.url);
                cleanTitle = this.title.replace(' - Ajuntament de Molins de Rei','');
                // link.text(this.title); <- cambio Israel
              link.html("<span>"+cleanTitle+"</span>");
              element.append(link);
              list.append(element);
          });
          $("#history-widget").empty().append(list);
      }

      getHistory();

      var url = document.location.href;
      var split = url.split("#");
      var title;
      if (split.length > 1) {
          title = $("#"+split[1]).text();
      } else {
          title = document.title;
      }
      if (title===undefined || title===null || $.trim(title)=="") title = url;
      addToHistory(title,url);
      url = split[0];
      /* este fragmento solo era para guardar enlaces falsos de los que tienen solo un hash

      $("a[href^='#']").click(function(){
          var link = $(this);
          var href = link.attr("href");
          var linkUrl = url+href;
          var title = $(href).text();
          if (title===undefined || title===null || $.trim(title)==="") title = linkUrl;
          addToHistory(title,linkUrl);
      });

      */














      //// Y ahora lo dificil: Fijar manualmente en la historia paginas

      function getKeepedHistory() { 
        var tmp2 = $.cookie("ajm_history_kept");
        if (tmp2===undefined || tmp2===null) tmp2 = "";
        if ($.trim(tmp2)=="") tmp2 = [];
        else tmp2 = tmp2.split("||");
        kept_history = [];
        //console.log('In kept history: ');
        $.each(tmp2, function(){
            var split = this.split("|");
            kept_history.push({
                title: split[0],
                url: split[1]
            });
            //console.log(split[0]+' - '+split[1]);
        });
        //console.log('In kept history: '+kept_history[0]);
      }

      function removeFromKeepedHistory(title,url) { 
        var tmp2 = $.cookie("ajm_history_kept");
        if (tmp2===undefined || tmp2===null) tmp2 = "";
        //console.log(tmp2);

        tmp2 = tmp2.replace("||"+title+"|"+url);
        tmp2 = tmp2.replace(title+"|"+url,"");

        if (tmp2===undefined || tmp2===null) tmp2 = "";
        if ($.trim(tmp2)=="") tmp2 = [];
        else tmp2 = tmp2.split("||");
        kept_history = [];
        //console.log('In kept history: ');
        $.each(tmp2, function(){
            var split = this.split("|");
            kept_history.push({
                title: split[0],
                url: split[1]
            });
            //console.log(split[0]+' - '+split[1]);
        });
        //console.log('In kept history: '+kept_history[0]);
      }

      function saveKeepedHistory() {
        var tmp2 = [];
        $.each(kept_history, function(){
            tmp2.push(this.title+"|"+this.url);
        });
        $.cookie("ajm_history_kept",tmp2.join("||"),{ expires: 365, path: "/" });
      }

      function addToKeepedHistory(title,url) {
        var newKeptHistory = []
        $.each(kept_history, function(){
            if (this.url!=url) newKeptHistory.push(this);
        });
        kept_history = newKeptHistory;
        if (kept_history.length>=12) { // max num planes guardar
          kept_history.shift();
        }
        kept_history.push({
            title: title,
            url: url
        });
        saveKeepedHistory();
        writeKeepedHistory();
      }

      function writeKeepedHistory() {
        var list = $("<ul class='uk-grid uk-grid-small history-keeped-list margin-bottom-10'  data-uk-grid='{gutter:10}' />");
        $.each(kept_history, function() {
            var element = $("<li class='uk-width-medium-1-3 uk-animation-slide-bottom' />");
            element.append('<button class="reset-btn hist-btn hist-btn-erase" title="'+str_history_desfixar_enllac+'" />');
            var link = $("<a />");
            link.attr("href",this.url);
              cleanTitle = this.title.replace(' - Ajuntament de Molins de Rei','');
              // link.text(this.title); <- cambio Israel
            link.html("<span>"+cleanTitle+"</span>");
            element.append(link);
            list.append(element);
        });
        $("#saved-history-widget").empty().append(list);
        eraseUiListener();
      }

      

      $(".hist-btn-save").click(function(){
        var link = $(this).parent().find('a'); // console.log( $(link).attr('href') );
        
        var href = $(link).attr("href");
        //var linkUrl = url+href; console.log(linkUrl);
        
        var title = $(link).text();
        if (title===undefined || title===null || $.trim(title)==="") title = linkUrl;
        addToKeepedHistory(title,href);
        writeKeepedHistory();

        $('.panel-history-content').animate({ scrollTop: 0 }, "slow");
        
      });

      function eraseUiListener() {
        $(".hist-btn-erase").hover( function(){
          $(this).next().addClass('bg-color-blood');
        });

        $(".hist-btn-erase").mouseout( function(){
          $(this).next().removeClass('bg-color-blood');
        });

        $(".hist-btn-erase").click( function(){
          var titleToErase = $(this).next().text();
          var urlToErase = $(this).next().attr('href');
          removeFromKeepedHistory(titleToErase, urlToErase);
          saveKeepedHistory();
          writeKeepedHistory();
        });
      }

      getKeepedHistory();
      writeKeepedHistory();
      eraseUiListener();

        

      


    }

























    // modal pop up de les adreces

    var modal_adreces = function () {
      $('.ajm-adreca .link-more').bind('click',function(){

        // recollim les dades de la adreca i omplim els texts del modal

        var adrecaTitol = $(this).closest('.ajm-adreca').find('.ad-title-tx').text();
        $('#ad-modal .ad-title-tx').text(adrecaTitol);

        var adrecaDireccion = $(this).closest('.ajm-adreca').find('.ad-address-tx').text();
        $('#ad-modal .ad-address-tx').text(adrecaDireccion);

        var adrecaCP = $(this).closest('.ajm-adreca').find('.ad-cp-tx').text();
        $('#ad-modal .ad-cp-tx').text(adrecaCP);

        var adrecaHoraris = $(this).closest('.ajm-adreca').find('.ad-horaris-tx').text();
        $('#ad-modal .ad-horaris-tx').text(adrecaHoraris);

        var adrecaTel = $(this).closest('.ajm-adreca').find('.ad-tel-tx').text();
        $('#ad-modal .ad-tel-tx').text(adrecaTel);
        var adrecaTelSanitized = $(this).closest('.ajm-adreca').find('.ad-tel-tx-a').text();
        $('#ad-modal .ad-tel-tx-a').attr('href','tel:'+adrecaTelSanitized );


        var adrecaMail = $(this).closest('.ajm-adreca').find('.ad-email-tx').text();
        $('#ad-modal .ad-email-tx .ad-email-tx-t').text(adrecaMail);
        $('#ad-modal .ad-email-tx .ad-email-tx-a').attr('href','mailto:'+adrecaMail );

        var adrecaWeb = $(this).closest('.ajm-adreca').find('.ad-web-tx').text();

        $('#ad-modal .ad-web-tx .ad-web-tx-t').text(adrecaWeb);
        $('#ad-modal .ad-web-tx .ad-web-tx-a').attr('href','adrecaWeb' );

        // botons accions socials etc

        // botor baixar adreca descarregar guardar

        function download(filename, text) {
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
          element.setAttribute('download', filename);
        
          element.style.display = 'none';
          document.body.appendChild(element);
        
          element.click();
        
          document.body.removeChild(element);
        }
        
        // Start file download.
        $('.widget-guardar a').click(function(e){
          e.preventDefault();
          download( adrecaTitol+".txt",
            adrecaTitol+"\r\n"+
            adrecaDireccion+"\r\n"+
            adrecaCP+"\r\n"+
            adrecaTel+"\r\n"+
            adrecaMail+"\r\n"+
            adrecaWeb+"\r\n\n"+
            adrecaHoraris
          );
        });

        // imprimir el modal

        $('.widget-imprimir a').click(function(e){
          e.preventDefault();
          $('head').append('<style>@media print { body > *:not(.uk-modal) { display:none; visibility:hidden; }}</style>');
          $('#ad-modal').appendTo('body');
          window.print();
        });
        
        // pintar gmap
        var latitud = $(this).attr('data-lat');
        var longitud = $(this).attr('data-lng');
        var direccion = $(this).attr('data-add');
        var elementid = "mapa";
        setupGoogleMap( latitud, longitud, direccion, elementid );
        
        
        
        
        


      });
    }




















    var setupGoogleMap = function( lati, longi, direcci, elementid ) {
      if (!elementid) {
        elementid = "mapa";
      }
      //console.log('Setting up google map');

      var map = null;
      var infoWindow = new google.maps.InfoWindow();

      function createMarker(point, texto) {
          var marker = new google.maps.Marker({
          position : point,
          map: map
          });
          map.setCenter(point);
          google.maps.event.addDomListener(marker, "click", function(){
          infoWindow.setContent(texto);
          infoWindow.setPosition(point);
          infoWindow.open(map);
          });
      }

      function initMap(lati, longi, direcci, elementid){
          if (!elementid) {
            elementid = "mapa";
          }
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address : direcci }, function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
              //var lat = results[0].geometry.location.lat();
              var lat = lati;
              //var lng = results[0].geometry.location.lng();
              var lng = longi;
              var mapOptions = {
              zoom: 15,
              center: new google.maps.LatLng(lat, lng),
              panControl: true,
              zoomControl: true,
              scaleControl: false,
              streetViewControl: false,
              mapTypeId: google.maps.MapTypeId.ROADMAP
              }
              //console.log('Map element id: #'+elementid);
              map = new google.maps.Map(document.getElementById(elementid), mapOptions);
              marca = new google.maps.LatLng(lat, lng);
              createMarker(marca, direcci );
          }
          });
      }
      initMap(lati, longi, direcci, elementid );
    }







    var mapaFitxaEquipament = function(mapan, mapaid) { // exemple: '.widget-mapa-2" , (#id)"mapa-2"
      if (!mapan) {
        mapan = "";
      } else {
        mapan = mapan;
      }
      
      //console.log('Mapa fitxa equipament element class: '+mapan);
      // pintar gmap
      var latitud = $('.widget-mapa'+mapan).attr('data-lat');
      var longitud = $('.widget-mapa'+mapan).attr('data-lng');
      var direccion = $('.widget-mapa'+mapan).attr('data-add');
      if (!mapaid) {
        mapaid = "mapa";
      }
      //console.log(latitud);
      //console.log(longitud);
      //console.log(direccion);
      setupGoogleMap( latitud, longitud, direccion, mapaid );
    }















    // action load more agenda ajax esdeveniments


    var ajaxLoadAgenda = function () {

      function repaint() {
        $(window).resize();
      }
  
      //console.log('ajaxload');
      $('.load-more-agenda').on('click', function () {
        console.log('click load');
        var button = $(this),
          data = {
            'action': 'load_esdeveniments',
            'page': button.attr('data-current'),
            'max_pages': button.attr('data-max'),
          };
  
        $.ajax({
          url: ajax_object.ajaxurl, // AJAX handler
          data: data,
          type: 'POST',
          beforeSend: function (xhr) {
            button.addClass('loading');
            $('.load-more-text, .load-more-spinner').toggleClass('uk-hidden');
          },
          success: function (data) {
  
            if (data) {
              button.removeClass('loading');
              $('.load-more-text,.load-more-spinner').toggleClass('uk-hidden');
              $('.llistat-agenda-principal').append(data); // insert new posts
  
              var current_page = button.attr('data-current');
              var max_page = button.attr('data-max');
  
              current_page++;
              button.attr('data-current', current_page);
  
              if (current_page == max_page) {
                button.remove(); // if last page, remove the button
              }
  
              setTimeout( repaint, 500); // forzar uk-grid margins, redibujar pagina un segundo despues
  
            } else {
              console.log('no data!');
              $('.llistat-agenda-principal').append("<li class='uk-width-1-1 uk-text-center'>NO DATAAA</li>");
              button.remove();
            }
  
          }
        });
      });
    }




    function agendaSharingTools() {
        $('.icon-button-share').click(function(){
            $('li').removeClass('data-current-sharing');
            $('.ajm-card-overlay, .ajm-card-share').addClass('uk-hidden');
            $(this).parent().parent().find('.ajm-card-overlay, .ajm-card-share').removeClass('uk-hidden');
            $(this).parent().parent().parent().addClass('data-current-sharing');
        });

        //clic al x tancar
        $('.ajm-card-share .uk-close').click(function(){
            $('li').removeClass('data-current-sharing');
            $(this).parent().parent().find('.ajm-card-overlay').addClass('uk-hidden');
            $(this).closest('.ajm-card-share').addClass('uk-hidden');
        });
        
        //clic al fondo blanc tambe tanca
        $('.uk-panel-agenda .ajm-card-overlay').click(function(){
            $('li').removeClass('data-current-sharing');
            $(this).addClass('uk-hidden');
            $(this).next().addClass('uk-hidden');
        });
    }



    
    function checkAgendaResults() {
      if ( $('.llistat-agenda-filtrada>*').length ) {
        $('.no-results-message').addClass('uk-hidden');
      } else {
        $('.no-results-message').removeClass('uk-hidden');
      }
    }
    




    var calendari = function() {

      if ( $(window).width() < 1024 ) {
        var sticky = UIkit.sticky('.calendar-toggler-1', { /* options */ });
      }

      //clic al calendari
      function filtrarFecha() {
        dateSelected = $(this).val()
        console.log( dateSelected );
        $('.llistat-agenda-principal').addClass('uk-hidden'); // ocultar el llistat inicial
        $('.llistat-agenda-filtrada').empty();
        $('.llistat-agenda-filtrada').removeClass('uk-hidden'); // mostrar el llistat filtrat

  
        //$('.load-more-agenda').remove();
        //$('.btn-reload').removeClass('uk-hidden');

        $('#all_activitats_hidden [data-date*="'+dateSelected+'"]').clone().appendTo('.llistat-agenda-filtrada');

        checkAgendaResults();
        $(window).resize();
        
        // mobile amagar filtres
        $('.col-agenda-tools').removeClass('uk-active');

        $('html, body').animate({
          scrollTop: ( $('#anchor-top-results').offset().top )
        },500);

        agendaSharingTools(); // listen to buttons
      }
  
      $("#datepicker").datepicker({
          //minDate : new Date(), // no fer servir min date, no permet consultar llavors mes anterior
          startDate: new Date(),// fecha actual
          showOn : "both",
          //dateFormat : 'yy-mm-dd',
          dateFormat : 'dd-mm-yy',
          beforeShowDay : function(date) {
  
              var zeroIzDay = "";
              if ( date.getDate() < 10) {
                  zeroIzDay = "0";
              }
  
              var zeroIzMon = "";
              if ( date.getMonth() < 9) {
                  zeroIzMon = "0";
              }
  
              dmy = zeroIzDay + date.getDate() + "-" + zeroIzMon + (date.getMonth() + 1) + "-" + date.getFullYear();
              
              if( $.inArray(dmy, availableDates)==-1 ) {
                  
                  return [true, 'noevent', ''];
                  // no se por que, lo hace al reves
              } else {
                console.log( dmy+" "+$.inArray(dmy, availableDates) );
                  return [true, 'event', 'Filtrar'];
              }
  
          },
          onSelect: filtrarFecha
  
      });
    }

    var canviNomColumnaDies = function(){
      $('.ui-datepicker-calendar th span').each(function(){
        //console.log('changing');
        var titleDay = $(this).attr('title');
        //console.log(titleDay);
        var newTitleDay;
        switch (titleDay) {
            case "dilluns" : newTitleDay="Dl";break;
            case "dimarts" : newTitleDay="Dt";break;
            case "dimecres" : newTitleDay="Dm";break;
            case "dijous" : newTitleDay="Dj";break;
            case "divendres" : newTitleDay="Dv";break;
            case "dissabte" : newTitleDay="Ds";break;
            case "diumenge" : newTitleDay="Dg";break;

            case "lunes" : newTitleDay="Lu";break;
            case "martes" : newTitleDay="Ma";break;
            case "miercoles" : newTitleDay="Mi";break;
            case "jueves" : newTitleDay="Ju";break;
            case "viernes" : newTitleDay="Vi";break;
            case "sabado" : newTitleDay="Sa";break;
            case "domingo" : newTitleDay="Do";break;

            default : break;
        }
        $(this).text(newTitleDay).addClass('text-changed');
      });
    }
    
    /* TOGGLER CALENDARI UI MOBILE */
    var uiCalendariMobile = function() {
      $('.calendar-toggler').click(function(){
        $('.col-agenda-tools').toggleClass('uk-active');
      });
    }
    

    var filtresAgenda = function() {
      //poner el toggler de la ui agenda mobile junto al titulo
      $('.calendar-toggler-1').insertAfter('.breads').removeClass('uk-hidden');

      // poner el icono de cerrar dentro del boton de chapar la ui mobile de la agenda
      $('.menu-panel-menu .close-btn svg').clone().appendTo('.calendar-toggler-mobile');

      $('#tipus_agenda, #llocs_agenda').change(function(){
        var type_query="";
        var type = $('#tipus_agenda').val();
        if (type!=null) {
          type_query="[data-category*="+type+"]";
        }
        var place_query="";
        var place = $('#llocs_agenda').val();
        if (place!=null) {
          place_query="[data-place*="+place+"]";
        }
        

        $('.llistat-agenda-principal').addClass('uk-hidden'); // ocultar el llistat inicial
        $('.llistat-agenda-filtrada').empty();
        $('.llistat-agenda-filtrada').removeClass('uk-hidden'); // mostrar el llistat filtrat

        $('#all_activitats_hidden '+type_query+place_query).clone().appendTo('.llistat-agenda-filtrada');

        checkAgendaResults();
        $(window).resize();

        // mobile amagar filtres
        $('.col-agenda-tools').removeClass('uk-active');
        $('html, body').animate({
          scrollTop: ( $('#anchor-top-results').offset().top )
        },500);

        agendaSharingTools(); // listen to buttons

      });

      $('.filtres-agenda .reset-btn, .no-results-message').click(function(){
        $('.llistat-agenda-principal').removeClass('uk-hidden'); // mostrar el llistat inicial
        $('.llistat-agenda-filtrada').empty();
        $('.llistat-agenda-filtrada').addClass('uk-hidden'); // ocultar el llistat filtrat

        $('#tipus_agenda, #llocs_agenda').prop('selectedIndex',0);

        
        $(window).resize();

        // mobile amagar filtres
        $('.col-agenda-tools').removeClass('uk-active');
        $('html, body').animate({
          scrollTop: ( $('#anchor-top-results').offset().top )
        },500);
        

      });

      $('.days-radios input').change(function(){

        var valor = $(this).val();
        
        $('.llistat-agenda-principal').addClass('uk-hidden'); // ocultar el llistat inicial
        $('.llistat-agenda-filtrada').empty();
        $('.llistat-agenda-filtrada').removeClass('uk-hidden'); // mostrar el llistat filtrat

        var dayquery="null";

        switch (valor) {
          case "today" :

            dayquery = ".es-hoy"; // class

            break;

          case "tomorrow" :

            dayquery = ".es-dema"; // class

            break;

          case "thisweek" :

            dayquery = ".semana-actual"; // class

            break;

          case "weekend" :

            dayquery = ".es-finde"; // class

            break;

          default :

            break;
        }

        $('#all_activitats_hidden .semana-actual'+dayquery).clone().appendTo('.llistat-agenda-filtrada');
        checkAgendaResults();
        // mobile amagar filtres
        $('.col-agenda-tools').removeClass('uk-active');
        $('html, body').animate({
          scrollTop: ( $('#anchor-top-results').offset().top )
        },500);

      });



    }

    var formatForms = function() {

      ///console.log('Formatting forms');
      $('form').addClass('uk-form');

      $('.widget .nf-form-wrap').addClass('widget-inner');
      $('.nf-field-container').addClass('uk-form-row');

      // ocultar labels

      $(' .nf-field input[type="text"],'+
          '.nf-field input[type="email"],'+
          '.nf-field textarea,'+
          '.nf-field input[type="tel"]').parent().prev().addClass('uk-hidden');

      // convertir en placeholders
      
      $(' .nf-field').each(function(){
        var labeltext = $(this).find('label').text();
        $(this).find('  input[type="text"],'+
                        'input[type="email"],'+
                        'textarea,'+
                        'input[type="tel"]').
                        attr('placeholder',labeltext);
      });

      $('.checkbox-container').parent().addClass('contains-checkbox');
      $('.nf-form-fields-required').parent().addClass('contains-required-msg');
      
    }








    // convert all sidebar widget menus to dropdown, based in ambits menu

    var widgetMenuToDropdown = function() {
      $('.uk-width-medium-1-4 .widget_nav_menu').addClass('ambit-nav uk-hidden-small');
      
      $('.uk-width-medium-1-4 .widget_nav_menu h3').wrapInner('<span class="ambit-nav-toggle-all" data-uk-toggle="{target:\'#ambit-nav-dropdown\', cls:\'uk-active\'}"></span>');

      $('.uk-width-medium-1-4 .widget_nav_menu .ambit-nav-toggle-all').prepend('\
      <button class="ambit-nav-toggle btn-reset reset-btn">\
        <span class="screen-reader-text">Mostrar menú</span>\
      </button>');

      //<ul id="ambit-nav-dropdown" class="ambit-nav-dropdown">

      $('.uk-width-medium-1-4 .widget_nav_menu ul.menu').attr('id','ambit-nav-dropdown').addClass('ambit-nav-dropdown');

    }

    // per mobile, que no quedi tan abaix el menu del sidebar. Es duplica i es posa versio mobile sticky

    var widgetMenuCloneForMobile = function() {
      $('.ambit-nav.uk-hidden-small').
      clone().appendTo('.page-header, .single-header').removeClass('uk-hidden-small').addClass('menu-mobile-secondary uk-visible-small');
      $('.menu-mobile-secondary #ambit-nav-dropdown').attr('id','ambit-nav-dropdown-m');
      $('.menu-mobile-secondary .ambit-nav-toggle-all').attr('data-uk-toggle','ambit-nav-dropdown-m');
      $('.menu-mobile-secondary .ambit-nav-toggle-all').bind('click', function(){
        $('#ambit-nav-dropdown-m').toggleClass('uk-active');
      });
    }



    // home, bloc temes i ambits, embolicar amb enllac a tots

    var linkMobileTemesAmbits = function() {
      var ambitslink = $('.uk-touch .modul.temes-i-ambits, .uk-touch .modul.temas-y-ambitos').find('.mod-morelink').attr('href');
      //console.log('Ambits link: '+ambitslink);
      $('.uk-touch .modul.temes-i-ambits, .uk-touch .modul.temas-y-ambitos').wrap('<a href="'+ambitslink+'"></a>');
    }


    // canviar href de links a ajuts archive, per plana cataleg ajuts

    var ajutsChangeLink = function() {
      $('#breadcrumbs a[href*="/ajuts/"]').attr('href',siteUrl+'/cataleg-ajuts/');
    }



    var mapaWebAccordion = function() {
      $('.wsp-container h2').wrap('<dt class="uk-accordion-title"></dt>');
      $('.wsp-container > ul').wrap('<dd class="uk-accordion-content"></dd>');
      $('.wsp-container').wrapInner('<dl class="uk-accordion ajm-accordion" data-uk-accordion="{showfirst:false}"></dl>');
    }




    // noticias leer mas ajax loader

    var ajaxLoadNoticias = function () {
      //console.log('ajaxload');
      $('.load-more-noticias').on('click', function () {
        //console.log('click load noticias');
        var button = $(this),
          data = {
            'action': 'load_noticies',
            'page': button.attr('data-current'),
            'max_pages': button.attr('data-max'),
          };

        $.ajax({
          url: ajax_object.ajaxurl, // AJAX handler
          data: data,
          type: 'POST',
          beforeSend: function (xhr) {
            button.addClass('loading');
            $('.load-more-text,.load-more-spinner').toggleClass('uk-hidden');
          },
          success: function (data) {

            if (data) {
              button.removeClass('loading');
              $('.load-more-text,.load-more-spinner').toggleClass('uk-hidden');
              $('.elements-list').append(data); // insert new posts
              //setTimeout( enableLazyLoad, 1000);
              enableLazyLoad();
              var current_page = button.attr('data-current');
              var max_page = button.attr('data-max');

              current_page++;
              button.attr('data-current', current_page);

              if (current_page == max_page) {
                button.remove(); // if last page, remove the button
              }
            } else {
              button.remove();
            }

            

          }
        });
      });
    }


    // equipamentscias leer mas ajax loader

    var ajaxLoadEquipaments = function () {
      //console.log('ajaxload');
      $('.load-more-equipaments').on('click', function () {
        //console.log('click');
        var button = $(this),
          data = {
            'action': 'load_equipaments',
            'page': button.attr('data-current'),
            'max_pages': button.attr('data-max'),
          };

        $.ajax({
          url: ajax_object.ajaxurl, // AJAX handler
          data: data,
          type: 'POST',
          beforeSend: function (xhr) {
            button.addClass('loading');
            $('.load-more-text,.load-more-spinner').toggleClass('uk-hidden');
          },
          success: function (data) {

            if (data) {
              button.removeClass('loading');
              $('.load-more-text,.load-more-spinner').toggleClass('uk-hidden');
              $('.elements-list').append(data); // insert new posts
              //setTimeout( enableLazyLoad, 1000);
              enableLazyLoad();
              var current_page = button.attr('data-current');
              var max_page = button.attr('data-max');

              current_page++;
              button.attr('data-current', current_page);

              if (current_page == max_page) {
                button.remove(); // if last page, remove the button
              }
            } else {
              button.remove();
            }

            

          }
        });
      });
    }

    var addDotsToWidgetLists = function() {
      $('.widget_recent_entries ul, .widget_categories ul').addClass('dotlist');
    }



      // grid and lightbox for common wordpress gallery galleries wp
    var lightBoxGalleries = function() {
      $('.gallery').addClass('uk-grid uk-grid-small uk-grid-margin uk-margin').attr('data-uk-grid-margin','');
      //UIkit.lightbox('.gallery');
      $('.gallery-columns-2>*').addClass('uk-width-1-2');
      $('.gallery-columns-3>*').addClass('uk-width-1-3'); 
      $('.gallery-columns-4>*').addClass('uk-width-1-4');
      $('.gallery-columns-5>*').addClass('uk-width-1-5');
      $('.gallery-columns-6>*,.gallery-columns-7>*,.gallery-columns-8>*,.gallery-columns-9>*').addClass('uk-width-1-4');
      $('.gallery-columns-6>*').addClass('uk-width-1-6');
      $('.gallery-columns-7>*').addClass('uk-width-1-10');
      $('.gallery-columns-8>*').addClass('uk-width-1-10');
      $('.gallery-columns-9>*').addClass('uk-width-1-10');
      $('.gallery-columns-10>*').addClass('uk-width-1-10');
      $('.gallery-columns-11>*').addClass('uk-width-1-12');
      $('.gallery-columns-12>*').addClass('uk-width-1-12');
      $('.gallery a').attr('data-uk-lightbox','');
    }



})(jQuery); // Fully reference jQuery after this point.
